import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { IconButton } from '@material-ui/core';
import { ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { CREATE_PROMPT_TEMPLATE_FEEDBACK } from 'gql/community';
import { showToast } from 'contexts/ToastContext';
import handleGraphQLError from 'util/error';
import PromptFeedbackModal from 'components/AlembicModalForm/PromptFeedbackModal';

const useStyles = makeStyles({
  actionButton: {
    color: '#000000'
  },
  disabled: {
    backgroundColor: 'transparent',
    cursor: 'default',
    pointerEvents: 'none'
  }
});

/**
 * @method
 * @summary This component renders the thumbs up and down buttons and the feedback modal per causal chain
 * @name Feedback
 * @return {Object} - React JSX
 */

const Feedback = props => {
  const { data, refetch } = props;
  const feedback = data?.feedback;
  const classes = useStyles();

  const [isPromptFeedbackModalOpen, togglePromptFeedbackModalOpen] = useState(false);

  const [createFeedback, { error, data: feedbackData }] = useMutation(
    CREATE_PROMPT_TEMPLATE_FEEDBACK
  );

  useEffect(() => {
    if (feedbackData?.createPromptTemplateFeedback) {
      showToast('Feedback sent.', 'success');
      refetch();
    }
  }, [feedbackData]);

  useEffect(() => {
    if (error) {
      handleGraphQLError(error);
    }
  }, [error]);

  const positiveFeedback = e => {
    createFeedback({
      variables: {
        input: {
          causal_chain_id: parseInt(data.id, 10),
          feedback_type: 'thumbs_up'
        }
      }
    });

    e.stopPropagation();
  };

  const negativeFeedback = e => {
    togglePromptFeedbackModalOpen(true);
    e.stopPropagation();
  };

  const isPositiveFeedback = feedback?.feedback_type === 'thumbs_up';
  const isNegativeFeedback = feedback?.feedback_type === 'thumbs_down';

  return (
    <>
      <IconButton
        className={`${classes.actionButton} ${isPositiveFeedback ? classes.disabled : ''}`}
        onClick={positiveFeedback}
      >
        {isPositiveFeedback ? <ThumbUp /> : <ThumbUpOutlined />}
      </IconButton>
      <IconButton
        className={`${classes.actionButton} ${isNegativeFeedback ? classes.disabled : ''}`}
        onClick={negativeFeedback}
      >
        {isNegativeFeedback ? <ThumbDown /> : <ThumbDownOutlined />}
      </IconButton>
      {data.id != null && isPromptFeedbackModalOpen && (
        <PromptFeedbackModal
          id={data.id}
          isModalOpen={isPromptFeedbackModalOpen}
          onChange={e => togglePromptFeedbackModalOpen(e.isModalOpen)}
          refetch={refetch}
        />
      )}
    </>
  );
};

Feedback.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    feedback: PropTypes.shape()
  }).isRequired,
  refetch: PropTypes.func
};

Feedback.defaultProps = {
  refetch: () => {}
};

export default Feedback;
