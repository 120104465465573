import { gql } from '@apollo/client';

export const FIND_DETECTED_EVENT = gql`
  query detectedEvent($id: ID, $startDateGraph: DateTimeISO, $endDateGraph: DateTimeISO) {
    detectedEvent(id: $id, startDateGraph: $startDateGraph, endDateGraph: $endDateGraph) {
      id
    }
  }
`;

export const DETECTED_EVENT = gql`
  query detectedEvent($id: ID, $startDateGraph: DateTimeISO, $endDateGraph: DateTimeISO) {
    detectedEvent(id: $id, startDateGraph: $startDateGraph, endDateGraph: $endDateGraph) {
      id
      graph(startDate: $startDateGraph, endDate: $endDateGraph) {
        event_label
        timeseries {
          x
          y
        }
      }
    }
  }
`;

export const DETECTED_EVENTS = gql`
  query detectedEvents(
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $startDateGraph: DateTimeISO
    $endDateGraph: DateTimeISO
    $eventType: String
    $typeFilter: [String]
    $after: Int
    $count: Int
  ) {
    detectedEvents(
      startDate: $startDate
      endDate: $endDate
      startDateGraph: $startDateGraph
      endDateGraph: $endDateGraph
      eventType: $eventType
      typeFilter: $typeFilter
      after: $after
      count: $count
    ) {
      count
      rows {
        id
        event_type
        event_start_date
        event_end_date
        magnitude
        mag_unit
        mag_base
        is_complete
        channel
        event_label
        secondary_event_label
        secondary_id
        eventInfo {
          postBody
          sourceName
        }
        linktoken {
          id
          type
          remote_name
        }
        campaignEvent {
          id
          linked_account_id
          completed_at
          published_url
        }
        graph(startDate: $startDateGraph, endDate: $endDateGraph) {
          event_label
          timeseries {
            x
            y
          }
        }
      }
    }
  }
`;

export const DETECTED_EVENTS_DRAWER_TABLE = gql`
  query detectedEvents(
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $eventType: String
    $eventLabel: String
  ) {
    detectedEvents(
      startDate: $startDate
      endDate: $endDate
      eventType: $eventType
      eventLabel: $eventLabel
    ) {
      id
      event_start_date
      event_end_date
      magnitude
      mag_unit
    }
  }
`;

export const LIST_DETECTED_EVENTS = gql`
  query listDetectedEvents(
    $startDate: DateTimeISO
    $endDate: DateTimeISO
    $eventType: String
    $eventLabel: String
    $after: Int
    $count: Int
    $orderBy: [String]
  ) {
    listDetectedEvents(
      startDate: $startDate
      endDate: $endDate
      eventType: $eventType
      eventLabel: $eventLabel
      after: $after
      count: $count
      orderBy: $orderBy
    ) {
      rows {
        id
        event_start_date
        event_end_date
        magnitude
        mag_unit
      }
      count
    }
  }
`;

export const DETECTED_EVENT_MENTION_GROUPS = gql`
  query detectedEvent($id: ID!, $getMentionGroups: Boolean!, $getArticleSearches: Boolean!) {
    detectedEvent(id: $id) {
      enabledMentionGroups @include(if: $getMentionGroups)
      enabledArticleSearches @include(if: $getArticleSearches)
    }
  }
`;
