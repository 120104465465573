const formatEventForDrawer = event => ({
  id: event.id,
  event_start_date: event.startDate,
  event_end_date: event.endDate,
  event_type: event.type,
  event_label: event?.eventInfo?.postBody,
  linktoken: event.linktoken ?? {},
  eventInfo: event.eventInfo,
  secondary_id: event.secondary_id
});

export default formatEventForDrawer;
