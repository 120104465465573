import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItemIcon, Collapse, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDropDown as Down, ArrowRight as Right } from '@material-ui/icons';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { linePalette } from 'components/AnalyticsSocial/AnalyticsGraphConsts';
import AlbLoading from 'components/AlbLoading';
import AlbError from 'components/AlbError';
import AlbTable from 'components/AlbTable';
import CustomFooter from 'components/TablePagination';
import { customValueRender } from 'components/AlbTable/CustomRender';
import GraphBody from 'components/PostDrawerView/GraphBody';
import GraphHeader from 'components/PostDrawerView/GraphHeader';
import Box from 'components/Box';
import EditInPlaceInput from 'components/EditInPlaceInput';
import { showToast } from 'contexts/ToastContext';
import {
  GET_MENTION_GROUP,
  GET_MENTION_GROUPS,
  UPDATE_MENTION_GROUP_TITLE
} from 'gql/mention_group';
import colors from 'util/colors';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';
import { excludedRatingsSourceTypes } from '../util';

const useStyles = makeStyles({
  container: {
    flex: 1
  },
  collapse: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  expandedIndicator: {
    height: '100%',
    width: '1px',
    backgroundColor: colors.darkGray
  },
  listItem: {
    minWidth: 'unset',
    color: colors.navy
  },
  wrapperInner: {
    position: 'relative',
    minHeight: '40px',

    '& > :last-child': {
      paddingBottom: '0px !important'
    }
  },
  legend: {
    margin: '10px 0px'
  },
  legendColor: {
    height: '16px',
    width: '16px',
    backgroundColor: linePalette.backgroundColor,
    borderRadius: '3px',
    marginRight: '10px'
  },
  legendText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '12px',
    color: '#6F6F6F',

    '& > :last-child': {
      color: '#0b1835'
    }
  },
  title: {
    marginRight: '10px',
    fontSize: '16px',
    fontWeight: 500,
    color: colors.navy
  },
  truncateText: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    lineClamp: 3,
    wordBreak: 'break-word',
    fontSize: '13px'
  }
});

const excludeSegmentEventTypes = [DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS];

const excludeRatingsEventTypes = [
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS
];

/**
 * @summary Merged graph and table for a single mention group for @see ThirdPartyMediaDrawerView
 * @name ThirdPartyMediaDrawerGraphAndTable
 * @return {Object} - React JSX
 */
const ThirdPartyMediaDrawerGraphAndTable = props => {
  const { details, extractSingleMentions, otherMentions } = props;
  const { id, ids, type, label, query, queryName, variables, startDate, endDate } = details;
  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState(0);

  const [renderMentionGroup, setRenderMentionGroup] = useState(false);
  const [narrativeTitle, setNarrativeTitle] = useState('');

  const startOfFirstDate = moment.utc(startDate).startOf('day');
  const endOfLastDate = moment.utc(endDate).endOf('day');

  const excludeSegment = excludeSegmentEventTypes.includes(type);
  const excludeRatings = excludeRatingsEventTypes.includes(type);

  const dateRangeOptions = [
    {
      label: '+/- 3D',
      range: {
        start: startOfFirstDate.clone().subtract(3, 'd'),
        end: endOfLastDate.clone().add(3, 'd')
      }
    },
    {
      label: '+/- 7D',
      range: {
        start: startOfFirstDate.clone().subtract(7, 'd'),
        end: endOfLastDate.clone().add(7, 'd')
      }
    },
    {
      label: '+/- 30D',
      range: {
        start: startOfFirstDate.clone().subtract(30, 'd'),
        end: endOfLastDate.clone().add(30, 'd')
      }
    }
  ];

  const { data, loading: graphLoading, error: graphError } = useQuery(query, {
    variables: {
      startDate: dateRangeOptions[selectedDateRange].range.start,
      endDate: dateRangeOptions[selectedDateRange].range.end,
      ...variables
    },
    fetchPolicy: 'network-only'
  });

  // if a single id is passed down, this component will render data for a single mg
  const { data: mgData, loading: mgLoading, error: mgError } = useQuery(GET_MENTION_GROUP, {
    variables: { id, startDate: startOfFirstDate, endDate: endOfLastDate, isDisabled: false },
    fetchPolicy: 'network-only',
    skip: otherMentions
  });

  // if an array of ids are passed down, this component will render data for a list of stray single mentions
  const { data: mgsData, loading: mgsLoading, error: mgsError } = useQuery(GET_MENTION_GROUPS, {
    variables: { ids, startDate: startOfFirstDate, endDate: endOfLastDate, isDisabled: false },
    fetchPolicy: 'network-only',
    skip: !otherMentions
  });

  const [updateMentionGroupTitle, { error: updateError, data: updateData }] = useMutation(
    UPDATE_MENTION_GROUP_TITLE
  );

  useEffect(() => {
    let currentTotal = 0;
    const labels = [];
    const datasets = [];

    if (data?.[queryName]?.length > 0) {
      const tempData = [];
      data[queryName].forEach(dataPoint => {
        labels.push(moment.utc(dataPoint.x).format('MMM D'));
        tempData.push(dataPoint.y);
        currentTotal += dataPoint.y;
      });

      datasets.push({
        label,
        type: 'bar',
        data: tempData,
        ...linePalette
      });
    }
    setTotal(currentTotal);
    setGraphData({ labels, datasets });
  }, [data]);

  const customTruncateRender = text => (
    <Tooltip title={text}>
      <Typography variantMapping={{ body1: 'span' }} className={classes.truncateText}>
        {text}
      </Typography>
    </Tooltip>
  );

  const tableColumns = [
    { name: 'source', label: 'SOURCE', options: { customBodyRender: customTruncateRender } },
    {
      name: 'segment',
      label: 'SEGMENT',
      options: { display: !excludeSegment, customBodyRender: customTruncateRender }
    },
    { name: 'airDate', label: 'AIR DATE' },
    { name: 'mentionText', label: 'MENTION', options: { customBodyRender: customTruncateRender } }
  ];

  if (!excludeRatings) {
    tableColumns.push({
      name: 'ratings',
      label: 'RATINGS',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        customBodyRender: (value, tableMeta) => (value ? customValueRender(value, tableMeta) : '-')
      }
    });
  }

  const formatMention = mention => ({
    ...(mention?.broadcast?.station_name &&
      mention?.broadcast?.network_affiliation && {
        source: `${mention.broadcast.station_name} (${mention.broadcast.network_affiliation})`
      }),
    ...(mention?.broadcast?.station_name &&
      mention?.broadcast?.network_affiliation == null && {
        source: mention.broadcast.station_name
      }),
    ...(mention?.broadcast?.network_affiliation &&
      mention?.broadcast?.station_name == null && {
        source: mention.broadcast.network_affiliation
      }),
    ...(mention?.podcast?.author && {
      source: mention.podcast.author
    }),
    ...(mention?.broadcast?.program_title && {
      segment: mention.broadcast.program_title
    }),
    ...(mention?.podcast?.podcast_title && {
      segment: mention.podcast.podcast_title
    }),
    ...(!mention?.broadcast?.program_title &&
      !mention?.podcast?.podcast_title && {
        segment: '-'
      }),
    ...(mention?.air_time && {
      airDate: moment.utc(mention.air_time).format('L LT')
    }),
    ...(mention?.broadcast_mention_text && {
      mentionText: mention.broadcast_mention_text
    }),
    ...(mention?.podcast_mention_text && {
      mentionText: mention.podcast_mention_text
    }),
    ...(mention?.viewership &&
      !excludeRatings &&
      !excludedRatingsSourceTypes.includes(mention.source) && {
        ratings: mention.viewership
      })
  });

  useEffect(() => {
    let tempTableData = [];
    const mentionCount = mgData?.getMentionGroup?.mentionsAveraged?.rows?.length;

    if (mgData?.getMentionGroup?.mentionsAveraged?.rows?.length) {
      tempTableData = mgData.getMentionGroup.mentionsAveraged.rows.map(formatMention);

      if (mentionCount > 1) {
        setRenderMentionGroup(true);
      } else {
        // extract mentions from groups that only contain 1
        extractSingleMentions(prevState => [...prevState, id]);
      }

      setNarrativeTitle(
        mgData.getMentionGroup.title ?? `Narrative ${mgData.getMentionGroup.group_seq + 1}`
      );
    }

    if (mgsData?.getMentionGroups?.length) {
      tempTableData = mgsData.getMentionGroups.map(mentionGroup => {
        const [singleMention] = mentionGroup.mentionsAveraged.rows;
        return formatMention(singleMention);
      });

      setRenderMentionGroup(true);
    }

    setTableData(tempTableData);
  }, [mgData, mgsData]);

  useEffect(() => {
    if (updateData?.updateMentionGroupTitle) {
      showToast('Narrative title updated.', 'success');
      setNarrativeTitle(updateData.updateMentionGroupTitle.title);
    }
  }, [updateData]);

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      []
    );

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    sort: false,
    rowsPerPage: 10,
    responsive: 'standard',
    customFooter
  };

  const EditNarrativeTitle = () => (
    <EditInPlaceInput
      useEdit
      useIcons
      value={narrativeTitle}
      onChange={event => updateMentionGroupTitle({ variables: { id, title: event.value } })}
      customTitleStyle={classes.title}
    />
  );

  // if mention group has 2 or more mentions, we display
  if (renderMentionGroup) {
    return (
      <Grid container style={{ padding: '25px' }}>
        <Grid
          item
          className={classes.collapse}
          onClick={() => setIsCollapsed(prevState => !prevState)}
        >
          <ListItemIcon classes={{ root: classes.listItem }}>
            {isCollapsed ? <Right /> : <Down />}
          </ListItemIcon>
          {!isCollapsed && <Box className={classes.expandedIndicator} />}
        </Grid>
        <Grid item className={classes.container}>
          {updateError && <AlbError error={updateError} />}
          <GraphHeader
            label={!otherMentions ? <EditNarrativeTitle /> : label}
            isCollapsed={isCollapsed}
            dateRangeOptions={dateRangeOptions}
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            hasGraph
          />
          <Collapse
            in={!isCollapsed}
            classes={{
              wrapperInner: classes.wrapperInner
            }}
          >
            <Grid container justifyContent="center">
              {(graphLoading || mgLoading || mgsLoading) && <AlbLoading />}
              {(graphError || mgError || mgsError) && (
                <AlbError error={graphError || mgError || mgsError} />
              )}
              {!graphLoading && !graphError && (
                <>
                  <GraphBody
                    graphData={graphData}
                    isCollapsed={isCollapsed}
                    graphChildren={[]}
                    maxWidth={480}
                    hasGraph
                  />
                  <Grid className={classes.legend} container justifyContent="center">
                    <Grid className={classes.legendColor} item />
                    <Grid className={classes.legendText} item>
                      <Grid item>{label}</Grid>
                      <Grid item>{total.toLocaleString()}</Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {!mgLoading && !mgsLoading && !mgError && !mgsError && (
                <AlbTable
                  tableData={tableData}
                  tableColumns={tableColumns}
                  tableOptions={options}
                  tableThemeOverrides={{
                    MUIDataTableHeadCell: {
                      root: {
                        whiteSpace: 'nowrap'
                      }
                    },
                    MuiTableCell: {
                      root: {
                        borderBottom: 'none',
                        '&:first-child': {
                          paddingLeft: '27px'
                        },
                        '&:last-child': {
                          paddingRight: '27px'
                        }
                      },
                      body: {
                        whiteSpace: 'wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }
                    },
                    MuiPaper: {
                      root: {
                        flex: 1
                      }
                    }
                  }}
                />
              )}
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    );
  }

  // otherwise, display nothing and send the single mention to be grouped
  return <></>;
};

ThirdPartyMediaDrawerGraphAndTable.propTypes = {
  details: PropTypes.shape().isRequired,
  extractSingleMentions: PropTypes.func,
  otherMentions: PropTypes.bool
};

ThirdPartyMediaDrawerGraphAndTable.defaultProps = {
  extractSingleMentions: () => {},
  otherMentions: false
};

export default ThirdPartyMediaDrawerGraphAndTable;
