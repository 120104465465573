/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import {
  CheckBox as CheckedIcon,
  CheckBoxOutlineBlank as UncheckedIcon,
  AddBoxOutlined,
  Edit as EditIcon
} from '@material-ui/icons';
import {
  Grid,
  Paper,
  List,
  MenuItem,
  Typography,
  Checkbox,
  Divider,
  Tooltip,
  Button,
  IconButton
} from '@material-ui/core';
import Select from 'react-select';
import { uniqBy, sortBy } from 'lodash';
import colors from 'util/colors';
import { LinkAccountsPath } from 'util/paths';
import { goToRoute } from 'util/routesHelpers';
import { mapOptions } from 'util/social';
import Box from 'components/Box';
import GroupAccountsModal from 'components/NewPostForm/GroupAccountsModal';
import AlembicModalConfirm from 'components/AlembicModalConfirm/AlembicModalConfirm';
import { DELETE_LINKED_GROUP } from 'gql/linktoken';
import { showToast } from 'contexts/ToastContext';
import getSimpleSocialType from 'util/getSimpleSocialType';
import SIMPLE_SOCIAL_TYPE_ENUM from 'util/getSimpleSocialTypeEnum';
import AccountItem from './AccountItem';
import StackedIcon from './StackedIcon';

const styles = {
  paper: {
    position: 'absolute',
    zIndex: 100,
    maxWidth: '750px'
    // left: 0,
    // right: 0
  },
  accountContainer: {
    flex: 1,
    minWidth: '300px'
    // overflow: 'hidden'
  },
  accountList: {
    flex: 1,
    overflowY: 'auto'
  }
};

const Menu = props => {
  const { children, selectProps, innerProps } = props;

  return (
    <Paper className={selectProps.classes.paper} {...innerProps}>
      {children}
      <Divider />
      <MenuItem
        onClick={() => goToRoute(LinkAccountsPath, selectProps.history)}
        style={{ padding: '12px 16px' }}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <AddBoxOutlined fontSize="small" style={{ marginRight: '8px' }} />
          <Typography>Link/Enable Social Accounts</Typography>
        </Grid>
      </MenuItem>
    </Paper>
  );
};

const Option = props => {
  const { children, data, innerProps, selectProps, isSelected } = props;
  const { onClick } = innerProps;

  return (
    <MenuItem onClick={onClick} style={{ padding: '12px 16px' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Checkbox
              style={{ padding: 0, marginRight: '10px' }}
              checked={isSelected}
              icon={<UncheckedIcon fontSize="small" />}
              checkedIcon={<CheckedIcon fontSize="small" />}
            />
            <StackedIcon
              accounts={data?.linktokens ? data.linktokens : [data]}
              width={20}
              height={20}
            />
            <Typography style={{ marginLeft: '10px' }}>{children}</Typography>
          </Box>
        </Grid>
        <Grid item>
          {data.linktokens && (
            <IconButton
              size="small"
              onClick={event => {
                event.stopPropagation();
                selectProps.toggleEdit(true);
                selectProps.setSelectedEdit(data);
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </MenuItem>
  );
};

const NoOptionsMessage = props => {
  const { children } = props;
  return (
    <Box py={10} display="flex" justifyContent="center" alignItems="center">
      <Typography>{children}</Typography>
    </Box>
  );
};

const MultiValueContainer = props => {
  const { data, selectProps } = props;
  const { id, platform } = data;

  const CustomTooltip = withStyles({
    tooltip: {
      backgroundColor: colors.blue,
      padding: '3px 10px',
      borderRadius: '0px'
    }
  })(Tooltip);

  const unique = uniqBy(selectProps.value, 'platform.name');
  const uniquePlatform = unique.find(account => account.id === id);
  const accountList = selectProps.value
    .filter(account => account.platform.name === platform.name)
    .map(account => (
      <Typography style={{ fontSize: 10 }} key={account.id}>
        {account.label}
      </Typography>
    ));

  return uniquePlatform ? (
    <Box mr={10} display="flex">
      <CustomTooltip title={accountList}>
        <img alt="logo" src={platform?.icon} style={{ width: 20, height: 20 }} />
      </CustomTooltip>
    </Box>
  ) : null;
};

const formatGroupLabel = data => (
  <Grid
    container
    style={{ background: '#EEEEEE', borderRadius: '5px', padding: '5px 0px 5px 14px' }}
  >
    <Typography
      style={{ fontWeight: '500', fontSize: '10px', lineHeight: '15px', color: '#000000' }}
    >
      {data.label}
    </Typography>
  </Grid>
);

const customStyles = {
  groupHeading: provided => ({
    ...provided,
    padding: 0,
    margin: 0
  }),
  group: provided => ({
    ...provided,
    padding: 0
  }),
  menuList: provided => ({
    ...provided,
    padding: 0
  })
};

const PostAccountSelector = props => {
  const {
    history,
    classes,
    handleSelectAccount,
    singleSelectedAccounts,
    singleAccountData,
    groupedAccountData,
    refetchGroups,
    isSocialPost,
    isGrouped,
    useIconDisplay,
    useDefault,
    boxPadding,
    placeholder,
    web
  } = props;

  const [selectedGroupAccounts, setSelectedGroupAccounts] = useState([]);
  const [showGroupAccountsButton, toggleGroupAccountsButton] = useState(false);
  const [createGroupAccountsModal, toggleCreateGroupAccountsModal] = useState(false);
  const [editGroupAccountsModal, toggleEditGroupAccountsModal] = useState(false);
  const [selectedEditGroup, setSelectedEditGroup] = useState({});
  const [value, setValue] = useState([]);
  const [list, setList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteGroup] = useMutation(DELETE_LINKED_GROUP, {
    onCompleted() {
      showToast(`The group ${selectedEditGroup?.name} was deleted.`, 'success');
      refetchGroups();
    },
    onError(err) {
      showToast(err.message, 'error');
    }
  });

  useEffect(() => {
    if (useDefault && singleAccountData.length && singleSelectedAccounts.length) {
      handleSelectAccount(mapOptions(singleSelectedAccounts, false));
    }
  }, []);

  // when account data changes, need to clear list of accounts in side panel (social post).
  useEffect(() => {
    setList([]);
  }, [singleAccountData]);

  useEffect(() => {
    if (selectedGroupAccounts.length) {
      let singleAccounts = [...singleSelectedAccounts];
      const groupedAccounts = [...selectedGroupAccounts];

      for (let i = 0; i < groupedAccounts.length; i += 1) {
        const group = groupedAccountData.find(
          groupedAccount => groupedAccount.id === groupedAccounts[i].id
        );

        const accountRemoveList = [];
        const groupNameRemoveList = [];

        singleAccounts.forEach(account => {
          // Go through the single account list and find which accounts need to be removed vs filtered
          if (account?.groupNames?.includes(groupedAccounts[i].name)) {
            if (account.groupNames.length > 1) {
              groupNameRemoveList.push(account.id);
            } else {
              accountRemoveList.push(account.id);
            }
          }
        });

        // Remove the account from the list
        singleAccounts = singleAccounts.filter(account => !accountRemoveList.includes(account.id));

        // Filter the account from the 'groupName' list
        for (let j = 0; j < singleAccounts.length; j += 1) {
          if (groupNameRemoveList.includes(singleAccounts[j].id)) {
            singleAccounts[j].groupNames = singleAccounts[j].groupNames.filter(
              groupName => groupName !== group.name
            );
          }
        }

        const mappedGroupedAccounts = mapOptions(group.linktokens, true);

        groupedAccounts[i] = {
          id: group.id,
          label: group.name,
          value: group.name,
          name: group.name,
          linktokens: mappedGroupedAccounts
        };
      }

      groupedAccounts.forEach(groupedAccount => {
        groupedAccount.linktokens.forEach(linktoken => {
          const existingAccount = singleAccounts.find(account => account.id === linktoken.id);

          // If the account does not exist add it to the list with the associated group name by key 'groupNames'
          if (!existingAccount) {
            singleAccounts.push({
              ...linktoken,
              groupNames: [groupedAccount.name]
            });
          } else {
            // If the account already exist in the list then add the group name to the key 'groupNames'
            const index = singleAccounts.findIndex(account => account.id === existingAccount.id);
            const existingGroupNames = existingAccount.groupNames || [];

            singleAccounts[index] = {
              ...existingAccount,
              groupNames: [...existingGroupNames, groupedAccount.name]
            };
          }
        });
      });

      handleSelectAccount(singleAccounts);
      setSelectedGroupAccounts([...groupedAccounts]);
    }
  }, [groupedAccountData]);

  useEffect(() => {
    const newValue = [];
    const newList = [];

    let showButton = false;

    if (selectedGroupAccounts?.length) {
      selectedGroupAccounts.forEach(account => {
        newValue.push(account);
        newList.push(account);
      });

      if (selectedGroupAccounts.length > 1) {
        showButton = true;
      }
    }

    if (singleSelectedAccounts?.length) {
      if (singleSelectedAccounts.length > 1 && !selectedGroupAccounts.length) {
        showButton = true;
      }

      const sortedSelectedAccounts = sortBy(singleSelectedAccounts, [
        a => a.platform?.sortIndex,
        'remote_name'
      ]);

      let uniqueSingleAccount = false;

      sortedSelectedAccounts?.forEach(account => {
        newValue.push(account);
        if (!account.groupNames || account.groupNames.length === 0) {
          newList.push(account);
          uniqueSingleAccount = true;
        }
      });

      //  selectedGroupAccounts.length == 1 && singleSelectedAccounts.includes(token does not have a group flag)
      if (selectedGroupAccounts.length === 1 && uniqueSingleAccount) {
        showButton = true;
      }
    }

    setValue(newValue);
    setList(newList);

    if (showButton !== showGroupAccountsButton) {
      toggleGroupAccountsButton(showButton);
    }
  }, [selectedGroupAccounts, singleSelectedAccounts]);

  const handleOnChange = (remainingOptions, event) => {
    let singleAccounts = singleSelectedAccounts;
    let groupedAccounts = selectedGroupAccounts;
    if (isGrouped) {
      // If select-option add to list
      if (event.action === 'select-option') {
        // If it is a grouped account add it and all linked accounts
        if (event.option.linktokens) {
          groupedAccounts.push(event.option);

          event.option.linktokens.forEach(linktoken => {
            const existingAccount = singleSelectedAccounts.find(
              account => account.id === linktoken.id
            );

            // If the account does not exist add it to the list with the associated group name by key 'groupNames'
            if (!existingAccount) {
              singleAccounts.push({
                ...linktoken,
                groupNames: [event.option.name]
              });
            } else {
              // If the account already exist in the list then add the group name to the key 'groupNames'
              const index = singleAccounts.findIndex(account => account.id === existingAccount.id);
              const existingGroupNames = existingAccount.groupNames || [];

              singleAccounts[index] = {
                ...existingAccount,
                groupNames: [...existingGroupNames, event.option.name]
              };
            }
          });
        } else {
          // If it is a single account just add it
          singleAccounts.push(event.option);
        }
      }

      // If deselect-option remove from list
      if (event.action === 'deselect-option') {
        // If it is a grouped account remove all accounts within the group
        if (event.option.linktokens) {
          const accountRemoveList = [];
          const groupNameRemoveList = [];

          singleAccounts.forEach(account => {
            // Go through the single account list and find which accounts need to be removed vs filtered
            if (account?.groupNames?.includes(event.option.name)) {
              if (account.groupNames.length > 1) {
                groupNameRemoveList.push(account.id);
              } else {
                accountRemoveList.push(account.id);
              }
            }
          });

          // Remove the account from the list
          singleAccounts = singleAccounts.filter(
            account => !accountRemoveList.includes(account.id)
          );

          // Filter the account from the 'groupName' list
          for (let i = 0; i < singleAccounts.length; i += 1) {
            if (groupNameRemoveList.includes(singleAccounts[i].id)) {
              singleAccounts[i].groupNames = singleAccounts[i].groupNames.filter(
                groupName => groupName !== event.option.name
              );
            }
          }

          groupedAccounts = groupedAccounts.filter(account => account.name !== event.option.name);
        } else {
          const targetAccount = singleSelectedAccounts.find(
            account => account.id === event.option.id
          );

          // If it is a single account, check for 'groupNames' key in object
          if (targetAccount.groupNames) {
            // const accountRemoveList = [];
            const groupNameCopy = [...targetAccount.groupNames];

            for (let i = 0; i < singleAccounts.length; i += 1) {
              if (singleAccounts[i].groupNames) {
                // Get the difference of the left array
                const difference = singleAccounts[i].groupNames.filter(
                  groupName => !groupNameCopy.includes(groupName)
                );

                singleAccounts[i].groupNames = difference;

                /*
                // If difference is 0 then mark it to be removed
                if (difference.length === 0) {
                  accountRemoveList.push(singleAccounts[i].id);
                } else {
                  // Filter out the common accounts
                  singleAccounts[i].groupNames = difference;
                } 
                */
              }
            }

            /* 
            singleAccounts = singleAccounts.filter(
              account => !accountRemoveList.includes(account.id)
            );
            */

            singleAccounts = singleAccounts.filter(account => account.id !== event.option.id);
            groupedAccounts = groupedAccounts.filter(
              account => !groupNameCopy.includes(account.name)
            );
          } else {
            // If it is a single account remove it
            singleAccounts = singleAccounts.filter(account => account.id !== event.option.id);
          }
        }
      }

      handleSelectAccount(singleAccounts);
      setSelectedGroupAccounts(groupedAccounts);
    } else {
      let remainingSelectedAccounts = [];

      if (remainingOptions?.length) {
        remainingSelectedAccounts = remainingOptions;
      }

      handleSelectAccount(remainingSelectedAccounts);
    }
  };

  const handleOnChangeWeb = (remainingOptions, event) => {
    if (event.action === 'select-option' && singleSelectedAccounts?.length > 0) {
      const { option: newOption } = event;

      if (newOption) {
        const newOptionType = getSimpleSocialType(newOption.type, true);
        let filteredValues;

        if (newOptionType === SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE) {
          filteredValues = remainingOptions.filter(
            ({ type }) =>
              getSimpleSocialType(type, true) !== SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS
          );
        }

        if (newOptionType === SIMPLE_SOCIAL_TYPE_ENUM.ADOBE_ANALYTICS) {
          filteredValues = remainingOptions.filter(
            ({ type }) => getSimpleSocialType(type, true) !== SIMPLE_SOCIAL_TYPE_ENUM.GOOGLE
          );
        }

        if (filteredValues?.length < remainingOptions.length) {
          handleSelectAccount(filteredValues);
          showToast(
            'Cannot combine data from separate analytics platforms into the same view. Please inspect one at a time.',
            'error'
          );
        } else {
          handleSelectAccount(remainingOptions);
        }
      }
    } else {
      let remainingSelectedAccounts = [];

      if (remainingOptions?.length) {
        remainingSelectedAccounts = remainingOptions;
      }

      handleSelectAccount(remainingSelectedAccounts);
    }
  };

  let options = [];

  if (isGrouped) {
    options.push({
      label: 'Groups',
      options: []
    });
    options.push({
      label: 'Social Accounts',
      options: []
    });

    if (groupedAccountData.length) {
      groupedAccountData.forEach(groupedAccount => {
        const mappedGroupedAccounts = mapOptions(groupedAccount.linktokens, true);

        options[0].options.push({
          id: groupedAccount.id,
          label: groupedAccount.name,
          value: groupedAccount.name,
          name: groupedAccount.name,
          linktokens: mappedGroupedAccounts
        });
      });
    }

    if (singleAccountData.length) {
      const mappedSingleAccounts = mapOptions(singleAccountData, true);
      options[1].options = mappedSingleAccounts;
    }
  } else {
    const mappedSingleAccounts = mapOptions(singleAccountData, false);

    options = mappedSingleAccounts;
  }

  const handleDeleteModalOpen = () => {
    toggleEditGroupAccountsModal(false);
    setDeleteModal(true);
  };

  const handleDeleteGroup = () => {
    if (selectedEditGroup) {
      deleteGroup({ variables: { id: selectedEditGroup.id } });
    }
  };

  return (
    <Grid container direction="column" wrap="nowrap" className={classes.accountContainer}>
      <Grid item>
        <Box px={boxPadding} pt={isSocialPost && 15}>
          <Select
            isMulti
            placeholder={placeholder}
            history={history}
            classes={classes}
            toggleEdit={toggleEditGroupAccountsModal}
            setSelectedEdit={setSelectedEditGroup}
            styles={customStyles}
            autoFocus={isSocialPost && !singleAccountData.length}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            controlShouldRenderValue={useIconDisplay}
            isClearable={useIconDisplay}
            components={{
              Option,
              NoOptionsMessage,
              Menu,
              ...(useIconDisplay && { MultiValueContainer })
            }}
            formatGroupLabel={formatGroupLabel}
            options={options}
            value={value}
            onChange={web ? handleOnChangeWeb : handleOnChange}
          />
        </Box>
      </Grid>
      {isSocialPost && (
        <Grid item className={classes.accountList}>
          <List style={{ paddingTop: 0 }}>
            {list.map(account => (
              <AccountItem
                key={account?.id}
                data={account}
                onChange={handleSelectAccount}
                selectedSingleAccounts={singleSelectedAccounts}
                selectedGroupedAccounts={selectedGroupAccounts}
                setSelectedGroupAccounts={setSelectedGroupAccounts}
                iconWidth={40}
                iconHeight={40}
              />
            ))}
          </List>
        </Grid>
      )}
      {isSocialPost && showGroupAccountsButton && (
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={() => toggleCreateGroupAccountsModal(!createGroupAccountsModal)}
        >
          Group Social Accounts
        </Button>
      )}
      <GroupAccountsModal
        create
        open={createGroupAccountsModal}
        onClose={() => toggleCreateGroupAccountsModal(!createGroupAccountsModal)}
        selectedAccountsProp={singleSelectedAccounts}
        allAccounts={singleAccountData && mapOptions(singleAccountData)}
        onChange={handleSelectAccount}
        refetchGroups={refetchGroups}
      >
        <Typography color="primary" variant="h3" style={{ paddingBottom: '15px' }}>
          Group Social Accounts
        </Typography>
        <Typography style={{ color: 'rgba(10, 23, 52, 0.5)' }} variant="h6">
          This will create a new group based on your selected accounts.
        </Typography>

        <Typography
          style={{
            color: 'rgba(10, 23, 52, 0.5)',
            fontSize: '15px',
            fontWeight: 600,
            paddingBottom: '15px'
          }}
        >
          Group Name
        </Typography>
      </GroupAccountsModal>
      {editGroupAccountsModal && (
        <GroupAccountsModal
          edit
          open={editGroupAccountsModal}
          onClose={() => toggleEditGroupAccountsModal(!editGroupAccountsModal)}
          group={selectedEditGroup}
          allAccounts={singleAccountData && mapOptions(singleAccountData)}
          refetchGroups={refetchGroups}
          handleGroupDelete={handleDeleteModalOpen}
        >
          <Typography
            color="primary"
            style={{ marginBottom: '30px', fontWeight: 500, fontSize: '24px', lineHeight: '36px' }}
          >
            Edit Group of Social Accounts
          </Typography>
          <Typography
            style={{
              color: 'rgba(10, 23, 52, 0.5)',
              fontSize: '15px',
              fontWeight: 600,
              paddingBottom: '15px'
            }}
          >
            Group Name
          </Typography>
        </GroupAccountsModal>
      )}
      <AlembicModalConfirm
        isOpen={deleteModal}
        isDelete
        title="Delete Group"
        body={
          <div>
            Are you sure you want to delete the group
            <Typography
              display="inline"
              style={{ color: 'black', fontWeight: '500', fontSize: '18px', lineHeight: '27px' }}
            >
              &nbsp;{selectedEditGroup.name}
            </Typography>
            ?
          </div>
        }
        handleCancel={() => {
          setDeleteModal(false);
          toggleEditGroupAccountsModal(true);
        }}
        handleConfirm={() => {
          handleDeleteGroup();
          setDeleteModal(false);
          toggleEditGroupAccountsModal(false);
        }}
      />
    </Grid>
  );
};

PostAccountSelector.propTypes = {
  history: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  handleSelectAccount: PropTypes.func.isRequired,
  singleSelectedAccounts: PropTypes.arrayOf(PropTypes.shape()),
  singleAccountData: PropTypes.arrayOf(PropTypes.shape()),
  groupedAccountData: PropTypes.arrayOf(PropTypes.shape()),
  refetchGroups: PropTypes.func,
  isSocialPost: PropTypes.bool,
  isGrouped: PropTypes.bool,
  useIconDisplay: PropTypes.bool,
  useDefault: PropTypes.bool,
  boxPadding: PropTypes.number,
  placeholder: PropTypes.string,
  web: PropTypes.bool
};

PostAccountSelector.defaultProps = {
  singleSelectedAccounts: [],
  singleAccountData: [],
  groupedAccountData: [],
  refetchGroups: null,
  isSocialPost: false,
  isGrouped: false,
  useIconDisplay: false,
  useDefault: false,
  boxPadding: 10,
  placeholder: 'Add Accounts',
  web: false
};

export default withRouter(withStyles(styles)(PostAccountSelector));
