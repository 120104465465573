import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles({
  container: {
    display: 'flex'
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  chevron: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    fontSize: '24px',
    padding: '0 10px'
  },
  leftOuter: {
    borderRight: '1px solid #0A1934',
    display: 'flex',
    alignItems: 'center'
  },
  leftMatter: {
    fontSize: '28px',
    fontWeight: 600,
    paddingRight: '10px',
    lineHeight: 1,
    verticalAlign: 'middle'
  },
  rightOuter: {
    // position: 'relative',
    // top: '4px'
  },
  rightTop: {
    paddingLeft: '10px',
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'right',
    lineHeight: 1.2
  },
  rightBottom: {
    paddingLeft: '10px',
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'right',
    lineHeight: 1.2
  }
});

const DateView = ({
  timeSlice,
  data,
  dateRange,
  handlePreviousTimeSlice,
  handleNextTimeSlice,
  disableNext
}) => {
  let leftMatter = 'leftMatter';
  let rightTop = 'right top';
  let rightBottom = 'right bottom';

  switch (timeSlice) {
    case 'Quarter':
      leftMatter = `${data?.causalChainsSummary?.time_period?.fiscal_year_text || ''} ${data
        ?.causalChainsSummary?.time_period?.fiscal_quarter_text || ''}`;
      rightTop = `${dateRange.range}`;
      rightBottom = `${dateRange.year}`;
      break;
    case 'Month':
      leftMatter = `${data?.causalChainsSummary?.time_period?.fiscal_year_text || ''} ${data
        ?.causalChainsSummary?.time_period?.fiscal_month_text || ''}`;
      rightTop = `${dateRange.range}`;
      rightBottom = `${dateRange.year}`;
      break;
    case 'Week':
      leftMatter = 'ThisWeek';
      rightTop = 'ThisWeek';
      rightBottom = 'ThisWeek';
      break;
    case 'Day':
      leftMatter = 'PreviousDay';
      rightTop = 'PreviousDay';
      rightBottom = 'PreviousDay';
      break;
    default:
      break;
  }
  const classes = useStyles();
  return (
    <div className={`${classes.container}`}>
      <button
        className={`${classes.chevron}`}
        type="button"
        onClick={() => handlePreviousTimeSlice()}
      >
        <ChevronLeftIcon />
      </button>
      <div className={`${classes.innerContainer}`}>
        <div className={`${classes.leftOuter}`}>
          <div className={`${classes.leftMatter}`}>{leftMatter}</div>
        </div>
        <div className={`${classes.rightOuter}`}>
          <div className={`${classes.rightTop}`}>{rightTop}</div>
          <div className={`${classes.rightBottom}`}>{rightBottom}</div>
        </div>
      </div>
      <button
        disabled={disableNext}
        className={`${classes.chevron}`}
        type="button"
        onClick={() => handleNextTimeSlice()}
      >
        <ChevronRightIcon />
      </button>
    </div>
  );
};

DateView.propTypes = {
  timeSlice: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
  dateRange: PropTypes.shape().isRequired,
  handlePreviousTimeSlice: PropTypes.func.isRequired,
  handleNextTimeSlice: PropTypes.func.isRequired,
  disableNext: PropTypes.bool
};

DateView.defaultProps = {
  disableNext: false
};

export default DateView;
