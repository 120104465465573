/* These types are names of redux actions */

export const CHECK_EXISTING_AUTH = 'CHECK_EXISTING_AUTH';
export const AUTHENTICATED = 'AUTHENTICATED';
export const DEAUTHENTICATE = 'DEAUTHENTICATE';
export const UPDATE_CONTAINER = 'UPDATE_CONTAINER';
export const SWITCH_CONTAINER = 'SWITCH_CONTAINER';
export const UPDATE_USER = 'UPDATE_USER';
export const MFA_ENROLL = 'MFA_ENROLL';
export const MFA_CHANGE_STATE = 'MFA_CHANGE_STATE';

export const FETCH_EVENT_CONFIG = 'FETCH_EVENT_CONFIG';
