import React, { useEffect, useContext } from 'react';
import store from 'store';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { DASHBOARD_INTELLIGENCE_EVENT } from 'gql/dashboardIntelligenceEvent';
import { MENTIONS_AND_RATINGS } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import { showToast } from 'contexts/ToastContext';
import { DETECTED_EVENT_TYPE, THIRD_PARTY_MEDIA_EVENT_TYPES } from 'util/detectedEvents';
import openDashboardDrawer from 'util/drawerUtil';
import formatEventForDrawer from 'util/formatEventForDrawer';

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    padding: '0px 5px',
    borderRadius: '5px'
  }
});

// DASHBOARD - INTELLIGENCE

const EventLink = props => {
  const { detected_event_id: id, description } = props;
  const classes = useStyles();

  const [getDetectedEvent, { data, error }] = useLazyQuery(DASHBOARD_INTELLIGENCE_EVENT, {
    variables: { id },
    fetchPolicy: 'network-only'
  });

  const drawerContext = useContext(DashboardIntelligenceDrawerContext);
  const socialPostDrawerContext = useContext(PostDrawerViewContext);

  const { configStoreEventTypes } = store.getState().config;

  useEffect(() => {
    if (data?.dashboardIntelligenceEvent) {
      const event = data.dashboardIntelligenceEvent;
      const originalObject = formatEventForDrawer(event);

      const newEvent = {
        ...((event.type === DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE ||
          event.type === DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL) && {
          detectedEventId: event.id
        }),
        ...(Object.keys(DETECTED_EVENT_TYPE)
          .concat(configStoreEventTypes)
          .includes(event.type) && {
          originalObject
        }),
        ...event,
        ...(THIRD_PARTY_MEDIA_EVENT_TYPES.includes(event.type) && {
          drawerType: MENTIONS_AND_RATINGS
        })
      };

      openDashboardDrawer(newEvent, drawerContext, socialPostDrawerContext);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showToast('Error retrieving event.', 'error');
    }
  }, [error]);

  return (
    <Typography component="span" className={classes.link} onClick={getDetectedEvent}>
      {description}
    </Typography>
  );
};

EventLink.propTypes = {
  detected_event_id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default EventLink;
