import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { customValueRender } from 'components/AlbTable/CustomRender';
import AlbTable from 'components/AlbTable';
import CustomFooter from 'components/TablePagination';
import { DETECTED_EVENT_TYPE } from 'util/detectedEvents';
import { excludedRatingsSourceTypes } from '../util';

const tableThemeOverrides = {
  MUIDataTableHeadCell: {
    root: {
      whiteSpace: 'nowrap'
    }
  },
  MuiTableCell: {
    root: {
      borderBottom: 'none',

      '&:first-child': {
        paddingLeft: '27px'
      },

      '&:last-child': {
        paddingRight: '27px'
      }
    },
    body: {
      whiteSpace: 'wrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  MuiPaper: {
    root: {
      flex: 1
    }
  }
};

const excludeRatingsEventTypes = [
  DETECTED_EVENT_TYPE.PC_SUMMARY_MENTIONS,
  DETECTED_EVENT_TYPE.BC_RADIO_MENTIONS
];

const ThirdPartyMediaDrawerMentions = props => {
  const { mentionGroupData, eventType } = props;

  const [tableData, setTableData] = useState([]);

  const excludeRatings = excludeRatingsEventTypes.includes(eventType);

  const narrativeColumns = [
    { name: 'source', label: 'SOURCE' },
    { name: 'segment', label: 'SEGMENT' },
    { name: 'airDate', label: 'AIR DATE' },
    {
      name: 'ratings',
      label: 'RATINGS',
      options: {
        setCellHeaderProps: () => ({ style: { textAlign: 'right' } }),
        customBodyRender: (value, tableMeta) => (value ? customValueRender(value, tableMeta) : '-')
      }
    }
  ];

  useEffect(() => {
    const tempTableData = [];

    if (mentionGroupData?.getMentionGroup?.mentionsAveraged?.rows?.length > 0) {
      mentionGroupData.getMentionGroup.mentionsAveraged.rows.forEach(mention => {
        tempTableData.push({
          ...(mention?.broadcast?.station_name &&
            mention?.broadcast?.station_guid && {
              source: `${mention.broadcast.station_name} (${mention.broadcast.station_guid})`
            }),
          ...(mention?.broadcast?.station_name &&
            mention?.broadcast?.station_guid == null && {
              source: mention.broadcast.station_name
            }),
          ...(mention?.broadcast?.station_guid &&
            mention?.broadcast?.station_name == null && {
              source: mention.broadcast.station_guid
            }),
          ...(mention?.podcast?.author && {
            source: mention.podcast.author
          }),
          ...(mention?.broadcast?.program_title && {
            segment: mention.broadcast.program_title
          }),
          ...(mention?.podcast?.podcast_title && {
            segment: mention.podcast.podcast_title
          }),
          ...(!mention?.broadcast?.program_title &&
            !mention?.podcast?.podcast_title && {
              segment: '-'
            }),
          ...(mention?.air_time && {
            airDate: moment.utc(mention.air_time).format('L LT')
          }),
          ...(mention?.broadcast_mention_text && {
            mentionText: mention.broadcast_mention_text
          }),
          ...(mention?.podcast_mention_text && {
            mentionText: mention.podcast_mention_text
          }),
          ...(mention?.viewership &&
            !excludeRatings &&
            !excludedRatingsSourceTypes.includes(mention.source) && {
              ratings: mention.viewership
            })
        });
      });
    }

    setTableData(tempTableData);
  }, [mentionGroupData]);

  const customFooter = (
    footerCount,
    footerPage,
    footerRowsPerPage,
    changeRowsPerPage,
    changePage,
    textLabels
  ) =>
    CustomFooter(
      footerCount,
      footerPage,
      footerRowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels,
      []
    );

  const options = {
    selectableRows: 'none',
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    serverSide: false,
    sort: false,
    rowsPerPage: 10,
    responsive: 'standard',
    customFooter
  };

  return (
    <AlbTable
      tableData={tableData}
      tableColumns={narrativeColumns}
      tableOptions={options}
      tableThemeOverrides={tableThemeOverrides}
    />
  );
};

ThirdPartyMediaDrawerMentions.propTypes = {
  mentionGroupData: PropTypes.shape().isRequired,
  eventType: PropTypes.string.isRequired
};

export default ThirdPartyMediaDrawerMentions;
