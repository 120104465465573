import { gql } from '@apollo/client';

export const CAUSAL_CHAINS_SUMMARY = gql`
  query causalChainsSummary($timeSlice: String!, $startDate: DateTimeISO!, $endDate: DateTimeISO!) {
    causalChainsSummary(timeSlice: $timeSlice, startDate: $startDate, endDate: $endDate) {
      id
      summary
      time_period {
        fiscal_year_text
        fiscal_quarter_text
        fiscal_month_text
        fiscal_week_text
        date_range
        period_type
        year
      }
    }
  }
`;

export const CAUSAL_CHAINS_SUMMARY_MOST_RECENT_DAY = gql`
  query causalChainsSummaryMostRecentDay {
    causalChainsSummaryMostRecentDay {
      end_date
    }
  }
`;

export const CREATE_PROMPT_TEMPLATE_FEEDBACK = gql`
  mutation createPromptTemplateFeedback($input: PromptTemplateFeedbackInput!) {
    createPromptTemplateFeedback(input: $input) {
      id
    }
  }
`;
