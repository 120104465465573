/* eslint-disable camelcase */
import React, { useState, useEffect, useContext, useRef } from 'react';
import store from 'store';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography
} from '@material-ui/core';
import { DASHBOARD_INTELLIGENCE_EVENTS } from 'gql/dashboardIntelligenceEvent';
import { GET_ANNOTATIONS_FOR_CONTAINER } from 'gql/annotation';
import { useQuery } from '@apollo/client';
import { getDatesForAnalytics } from 'util/date';
import openDashboardDrawer from 'util/drawerUtil';
import DashboardIntelligenceDrawerContext from 'contexts/DashboardIntelligenceDrawerContext';
import PostDrawerViewContext from 'contexts/PostDrawerViewContext';
import AlbLoading from 'components/AlbLoading';
import { EventsLegend } from 'components/AlbGraphs/Legend';
import SocialLogo from 'components/SocialLogo';
import AssetThumbnail from 'components/AssetThumbnail';
import Box from 'components/Box';
import AlbSwitch from 'components/AlbSwitch';
import { AnnotationBlueIcon } from 'util/assets';
import handleGraphQLError from 'util/error';
import { DETECTED_EVENT_TYPE, THIRD_PARTY_MEDIA_EVENT_TYPES } from 'util/detectedEvents';
import colors from 'util/colors';
import formatValueWithSymbol from 'util/formatValueWithSymbol';
import getSimpleSocialType from 'util/getSimpleSocialType';
import { MENTIONS_AND_RATINGS } from 'components/AnalyticsThirdPartyMedia/ThirdPartyMediaConsts';
import SIMPLE_SOCIAL_TYPE_ENUM from 'util/getSimpleSocialTypeEnum';
import formatEventForDrawer from 'util/formatEventForDrawer';

const DetectedEventsHeatmap = ({
  isToggleSwitchOn,
  setIsToggleSwitchOn,
  selectedDates,
  setSelectedDates
}) => {
  const [squareSize, setSquareSize] = useState(28);
  const [fontSize, setFontSize] = useState(15);
  const [minWidth, setMinWidth] = useState(110);
  const squareVerticalGap = 10;
  const squareHorizontalGap = 3;
  const annotationHeight = 5;
  const flyoverMaxWidth = 750;
  const gradient = ['#B1B0C7', '#9192B3', '#58598E', '#32327D'];
  const percentageRanges = gradient.map((color, i) => ({
    color,
    percentage: (75 / (gradient.length - 1)) * i
  }));

  const flyoverColumns = ['LENGTH', 'METRIC', '% CHANGE', 'VALUATION CHANGE', 'SOURCE', 'DETAILS'];

  const { start, end } = getDatesForAnalytics(
    moment()
      .subtract(30, 'd')
      .utc()
      .toISOString(),
    moment()
      .subtract(1, 'd')
      .utc()
      .toISOString()
  );

  const drawerContext = useContext(DashboardIntelligenceDrawerContext);
  const socialPostDrawerContext = useContext(PostDrawerViewContext);

  const [heatmapDateRange, setHeatmapDateRange] = useState({ start, end });

  // 'media query' substitute used here since the style object would be unreasonably long if done in CSS
  const handleResize = () => {
    let newSquareSize = 28;
    let newFontSize = 15;
    let newMinWidth = 110;

    if (window.innerWidth <= 1280) {
      newSquareSize = 24;
      newFontSize = 12;
      newMinWidth = 100;
    }

    if (window.innerWidth <= 1140) {
      newSquareSize = 20;
      newFontSize = 10;
      newMinWidth = 92;
    }

    setSquareSize(newSquareSize);
    setFontSize(newFontSize);
    setMinWidth(newMinWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  const useStyles = makeStyles({
    container: {
      backgroundColor: 'white',
      boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.1)'
    },
    // Header
    tabOverRide: {
      minWidth: 'unset',
      maxWidth: 'unset',
      cursor: 'default',
      fontSize: '18px',
      fontWeight: '500'
    },
    switchContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '24px'
    },
    switchHeaderText: {
      fontWeight: '500',
      fontSize: '18px',
      lineHeight: '27px'
    },
    // Body
    bodyContainer: {
      minHeight: '280px',
      position: 'relative',
      padding: '24px'
    },
    loadingOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'none',
      zIndex: 1
    },
    // Heat map
    graph: {
      position: 'relative',
      display: 'inline-grid',
      gridTemplateAreas: `"types squares" "empty days" "empty annotations"`,
      gridTemplateColumns: 'auto 1fr',
      columnGap: '10px',

      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: `${fontSize}px`,
      color: '#6F6F6F'
    },
    types: {
      display: 'grid',
      gridArea: 'types',
      height: `${squareSize}px`,
      lineHeight: `${squareSize}px`,
      rowGap: `${squareVerticalGap}px`,
      minWidth: `${minWidth}px`
    },
    squares: {
      display: 'grid',
      gridArea: 'squares',
      rowGap: `${squareVerticalGap}px`,
      columnGap: `${squareHorizontalGap}px`,
      gridTemplateColumns: `repeat(30, 1fr)`,
      gridAutoColumns: squareSize,
      gridAutoRows: squareSize,
      marginBottom: '10px !important',

      // each individual square
      '& > div': {
        borderRadius: '3px',
        position: 'relative',
        height: `${squareSize}px`,
        width: `${squareSize}px`,
        cursor: 'pointer'
      }
    },
    annotations: {
      display: 'grid',
      gridArea: 'annotations',
      columnGap: `${squareHorizontalGap}px`,
      marginTop: `${(squareSize + annotationHeight) * -1}px`
    },
    annotation: {
      position: 'relative',
      backgroundColor: '#009FC4',
      borderRadius: '10px',
      height: `${annotationHeight}px`,
      opacity: '50%'
    },
    annotationDays: {
      display: 'grid',
      gridArea: 'annotations',
      gridTemplateColumns: `repeat(30, 1fr)`,
      marginTop: `${(squareSize + annotationHeight) * -1}px`
    },
    annotationDay: {
      position: 'relative',
      height: `${annotationHeight}px`
    },
    flyover: {
      position: 'absolute',
      backgroundColor: 'white',
      boxShadow: '0px 0px 13px rgba(0, 0, 0, 0.2)',
      borderRadius: '3px',
      padding: '10px',
      zIndex: 2,
      maxWidth: `${flyoverMaxWidth}px`,
      cursor: 'default',

      '& > table': {
        display: 'block',
        overflow: 'hidden'
      }
    },
    flyoverTitle: {
      color: '#0A1734',
      whiteSpace: 'nowrap',
      marginBottom: '10px',
      fontSize: '15px',
      textAlign: 'left'
    },
    flyoverHeader: {
      '& > td': {
        padding: '0px 15px 10px 0px',
        fontSize: '12px',
        fontWeight: 500,
        borderBottom: 'none',
        whiteSpace: 'nowrap'
      },

      '& > :last-child': {
        paddingRight: '0px'
      }
    },
    flyoverRow: {
      '& > td': {
        padding: '0px 15px 10px 0px',
        fontSize: '12px',
        borderBottom: 'none'
      },

      '& > td:nth-child(-n+4)': {
        paddingRight: '10px'
      },

      '& > :nth-last-child(2)': {
        width: '150px'
      },

      '& > :last-child': {
        width: '150px',
        paddingRight: '0px'
      }
    },
    detailsButton: {
      border: '1px solid #6F6F6F',
      color: '#6F6F6F',

      '& > span': { fontSize: '12px' }
    },
    cellContent: {
      alignItems: 'center',
      whiteSpace: 'nowrap',
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      '& > img': {
        marginRight: '5px',
        verticalAlign: 'middle'
      },

      '& > span': {
        verticalAlign: 'middle'
      }
    },
    inlineAsset: {
      display: 'inline-block',
      marginRight: '5px'
    },
    days: {
      display: 'grid',
      gridArea: 'days',
      gridGap: `${squareHorizontalGap}px`,
      gridAutoFlow: 'column',
      gridAutoColumns: squareSize,
      lineHeight: `${squareSize}px`,
      overflow: 'hidden'
    },
    day: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      color: '#32327D',

      '& > :first-child': {
        marginBottom: `${annotationHeight}px`
      }
    },
    monthStart: {
      borderLeft: '1px solid #979797',
      height: `${squareSize * 2}px`
    },
    selectedDate: {
      position: 'absolute',
      backgroundColor: 'transparent',
      height: `${squareSize * 5 + squareVerticalGap * 3 + 20}px`,
      width: `${squareSize + 2}px`,
      border: '1px solid #32327D',
      borderRadius: '5px',
      marginLeft: '-1px',
      marginBottom: `${squareSize + annotationHeight - 5}px`,
      bottom: '0px',
      pointerEvents: 'none'
    },
    annotationIcon: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginRight: '10px'
    },
    annotationsList: {
      display: 'grid',

      '& > :not(:last-child)': {
        marginBottom: '10px'
      }
    },
    annotationRow: {
      display: 'flex',
      color: '#000000',
      fontWeight: 400
    },
    annotationText: {
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      lineClamp: 2,
      wordBreak: 'break-word',
      fontSize: '12px',
      marginRight: '10px'
    },
    annotationDates: {
      whiteSpace: 'nowrap',
      fontSize: '12px'
    }
  });

  const classes = useStyles();

  // map datePicker to heatmap. We want the date to be in the middle, unless there are not enough days.
  useEffect(() => {
    // check if we need to move the heatmap range.
    // Per spec, only need to move if selected date is within the last or first 4 days of the range.
    const startThreshold = moment.utc(heatmapDateRange.start).add(4, 'days');
    const endThreshold = moment.utc(heatmapDateRange.end).subtract(4, 'days');

    if (
      moment.utc(selectedDates.start) < startThreshold ||
      moment.utc(selectedDates.start) > endThreshold
    ) {
      const lastPossibleDate = moment.utc().subtract(1, 'days');

      let heatmapEndDate = lastPossibleDate;
      let heatmapStartDate = moment.utc(lastPossibleDate).subtract(29, 'days');

      if (moment.utc(selectedDates.start).add(15, 'd') < lastPossibleDate) {
        heatmapEndDate = moment.utc(selectedDates.start).add(15, 'd');
        heatmapStartDate = moment.utc(selectedDates.start).subtract(14, 'd');
      }

      // always use the getDatesForAnalytics function when setting heatmapDateRange to properly convert the time for the query.
      const { start: startDate, end: endDate } = getDatesForAnalytics(
        heatmapStartDate,
        heatmapEndDate
      );

      setHeatmapDateRange({ start: startDate, end: endDate });
    }
  }, [selectedDates]);

  const [squares, setSquares] = useState([]);
  const [days, setDays] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [annotationsLookup, setAnnotationsLookup] = useState({});
  const [annotationsByDayLookup, setAnnotationsByDayLookup] = useState([]);
  const [max, setMax] = useState(null);

  const types = ['media', 'social', 'web', 'conversion'];

  const generateSquares = detectedEvents => {
    const allEvents = types
      .map(type => detectedEvents[type].map(event => ({ type, ...event })))
      .flat();

    setSquares(allEvents);
    setMax(Math.max(...allEvents.map(({ count }) => count)));
  };

  const squareClick = day => {
    const utcDate = moment.utc(day);
    const date = new Date(utcDate.get('year'), utcDate.get('month'), utcDate.get('date'));

    setSelectedDates({
      start: date,
      end: date
    });
  };

  const { data: detectedEventsByGroupData, loading: eventsLoading, error: eventsError } = useQuery(
    DASHBOARD_INTELLIGENCE_EVENTS,
    {
      variables: {
        types: types.map(type => type.toUpperCase()),
        startDate: heatmapDateRange.start,
        endDate: heatmapDateRange.end,
        enableNewDetectedEvents: true,
        useNewEventToggle: !isToggleSwitchOn
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (detectedEventsByGroupData?.dashboardIntelligenceEvents?.all) {
      const { all } = detectedEventsByGroupData.dashboardIntelligenceEvents;

      generateSquares(detectedEventsByGroupData.dashboardIntelligenceEvents);
      setDays(all.map(({ day }) => [day, moment.utc(day).format('D')]));
    }
  }, [detectedEventsByGroupData]);

  const enumerateDaysBetweenDates = (startDate, endDate) => {
    const dates = [];
    const now = moment(startDate).clone();

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format());
      now.add(1, 'days');
    }

    return dates;
  };

  const generateAnnotations = annotationsForContainer => {
    const heatmapStartDate = moment.utc(heatmapDateRange.start);
    const heatmapEndDate = moment.utc(heatmapDateRange.end);
    const heatmapRange = enumerateDaysBetweenDates(heatmapStartDate, heatmapEndDate);

    const list = annotationsForContainer.reduce(
      (acc, { id, start_date: annoStart, end_date: annoEnd }) => {
        const annoStartDate = moment.utc(annoStart).startOf('d');
        // +1 day append here
        const annoEndDate = moment
          .utc(annoEnd)
          .add(1, 'd')
          .startOf('d');
        const layer = [];

        /*
          Scenario 1: Annotation starts before window and ends inside window

          - - - | 30 Day Window | - - -
          - - | Annotation | - - - - -
        */
        if (
          annoStartDate.isBefore(heatmapStartDate) &&
          annoEndDate.isSameOrBefore(heatmapEndDate)
        ) {
          const length = annoEndDate.diff(heatmapStartDate, 'd');

          layer.push({ length, count: 1, id, annoStart, annoEnd });

          acc.push(layer);
          return acc;
        }

        /*
          Scenario 2: Annotation start/end day is after last event date and between window

          - - - | # # # # # # # # 30 Day Window # # # # # # # # | - - -
          - - - - | Last Event | - - | Annotation | - - - - - - - - - -
        */
        if (
          annoStartDate.isSameOrAfter(heatmapStartDate) &&
          annoEndDate.isSameOrBefore(heatmapEndDate)
        ) {
          const gapLength = annoStartDate.diff(heatmapStartDate, 'd');

          if (gapLength >= 1) {
            layer.push({ length: gapLength, count: 0, id: `${id}gap` });
          }

          const length = Math.round(annoEndDate.diff(annoStartDate, 'd', true));

          layer.push({ length, count: 1, id, annoStart, annoEnd });

          acc.push(layer);
          return acc;
        }

        /*
          Scenario 3: Annotation starts inside window and ends after window

          - - - | 30 Day Window | - - -
          - - - - - - | Annotation | -
        */
        if (
          // annoStartDate.isSameOrAfter(previousEndDate) &&
          annoStartDate.isSameOrAfter(heatmapStartDate) &&
          annoEndDate.isAfter(heatmapEndDate)
        ) {
          const gapLength = annoStartDate.diff(heatmapStartDate, 'd');

          if (gapLength >= 1) {
            layer.push({ length: gapLength, count: 0, id: `${id}gap` });
          }

          const length = Math.round(heatmapEndDate.diff(annoStartDate, 'd', true));

          layer.push({ length, count: 1, id, annoStart, annoEnd });

          acc.push(layer);
          return acc;
        }

        /*
          Scenario 4: Annotation is larger than window

          - - - | 30 Day Window | - - -
          - - | # # Annotation # # | - -
        */
        if (
          annoStartDate.isSameOrBefore(heatmapStartDate) &&
          annoEndDate.isSameOrAfter(heatmapEndDate)
        ) {
          const length = Math.round(heatmapEndDate.diff(heatmapStartDate, 'd', true));

          layer.push({ length, count: 1, id, annoStart, annoEnd });

          acc.push(layer);
          return acc;
        }

        return acc;
      },
      []
    );

    const annotationsByDay = heatmapRange.map(date => [
      // returning the date to create keys
      date,
      annotationsForContainer
        .filter(
          ({ start_date: startDate, end_date: endDate }) =>
            moment.utc(date).isBetween(startDate, endDate) ||
            (moment.utc(date).isSameOrBefore(endDate) && moment.utc(date).isSameOrAfter(startDate))
        )
        .map(({ id }) => id)
    ]);

    setAnnotations(list);
    setAnnotationsByDayLookup(annotationsByDay);
  };

  const createAnnotationsLookup = list => {
    const lookup = list.reduce((acc, curr) => {
      acc[curr.id] = curr;

      return acc;
    }, {});

    setAnnotationsLookup(lookup);
  };

  const { data: annotationsData, loading: annotationsLoading, error: annotationsError } = useQuery(
    GET_ANNOTATIONS_FOR_CONTAINER,
    {
      variables: {
        startDate: heatmapDateRange.start,
        endDate: heatmapDateRange.end
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (annotationsData?.annotationsForContainer && !annotationsLoading) {
      generateAnnotations(annotationsData.annotationsForContainer);
      createAnnotationsLookup(annotationsData.annotationsForContainer);
    }
  }, [annotationsData]);

  const { configStoreEventTypes } = store.getState().config;

  const renderLogoForEventTypes = [
    DETECTED_EVENT_TYPE.SL_CLICKS,
    ...Object.keys(DETECTED_EVENT_TYPE).filter(
      eventType =>
        eventType.startsWith('BC') ||
        eventType.startsWith('PC') ||
        eventType.startsWith('NC_PC') ||
        eventType.startsWith('NC_BC') ||
        eventType.startsWith('SB') ||
        eventType.startsWith('SP')
    ),
    ...configStoreEventTypes
  ];

  const Flyover = ({ list, dates, offset }) => (
    <div
      className={classes.flyover}
      style={{
        top: `${squareSize / 2}px`,
        ...(offset + flyoverMaxWidth > window.innerWidth
          ? { right: `${squareSize / 2}px` }
          : { left: `${squareSize / 2}px` })
      }}
      role="button"
      aria-hidden="true"
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <div className={classes.flyoverTitle}>
        {`${list.length} Event${list.length > 1 ? 's' : ''} - ${dates
          .map(date => moment.utc(date).format('MM/DD/YYYY'))
          .join(' - ')}`}
      </div>
      <Table className={classes.flyoverBody}>
        <TableBody>
          <TableRow className={classes.flyoverHeader}>
            <TableCell />
            {flyoverColumns.map(column => (
              <TableCell key={column}>{column}</TableCell>
            ))}
          </TableRow>
          {list.slice(0, 4).map(event => {
            const {
              id,
              campaignEvent,
              startDate,
              endDate,
              metric,
              metricValue,
              growth,
              sourceName,
              valuation,
              eventInfo
            } = event;

            let { sourceType } = event;

            const eventLength = `${moment(endDate).diff(startDate, 'd')}D`;
            const magnitude = formatValueWithSymbol(metricValue, {
              usePrefixSymbol: metric === 'revenue'
            });
            const changeColor = `${growth > 0 ? '#05A74F' : ''}${growth < 0 ? '#E81828' : ''}${
              !growth ? '#000000' : ''
            }`;
            let change = '-';

            if (growth != null) {
              if (growth === metricValue && growth === valuation) {
                change = <>&infin;%</>;
              } else {
                change = formatValueWithSymbol(growth, {
                  usePositive: true,
                  usePostfixSymbol: true
                });
              }
            }
            const eventValue = formatValueWithSymbol(valuation, {
              usePrefixSymbol: metric === 'revenue'
            });

            const values = [
              { key: 'days', color: '#6F6F6F', value: eventLength },
              { key: 'mag', color: '#33337E', value: magnitude },
              { key: 'change', color: changeColor, value: change },
              { key: 'val', color: '#33337E', value: eventValue }
            ];

            let text = '-';

            if (campaignEvent?.event_body) {
              if (campaignEvent?.event_body?.length > 30) {
                text = campaignEvent?.event_body?.slice(0, 27)?.concat('...');
              } else {
                text = campaignEvent?.event_body;
              }
            }

            if (eventInfo?.postBody) {
              if (eventInfo.postBody.length > 30) {
                text = eventInfo.postBody.slice(0, 27)?.concat('...');
              } else {
                text = eventInfo.postBody;
              }
            }

            let name = sourceName || eventInfo?.sourceName || '-';

            if (sourceType === SIMPLE_SOCIAL_TYPE_ENUM.SHORTLINK) {
              const temp = name;
              name = text;
              text = temp;
            }

            if (!sourceType && renderLogoForEventTypes.includes(event?.type)) {
              sourceType = getSimpleSocialType(event.type, true);
            }

            return (
              <TableRow key={id} className={classes.flyoverRow}>
                {/* Details button */}
                <TableCell>
                  <Button
                    className={classes.detailsButton}
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      const originalObject = formatEventForDrawer(event);
                      const newEvent = {
                        ...((event.type === DETECTED_EVENT_TYPE.GA_USERS_BY_SOURCE ||
                          event.type === DETECTED_EVENT_TYPE.GA_CONVERSIONS_BY_GOAL) && {
                          detectedEventId: event.id
                        }),
                        ...(Object.keys(DETECTED_EVENT_TYPE)
                          .concat(configStoreEventTypes)
                          .includes(event.type) && {
                          originalObject
                        }),
                        ...event,
                        // Third party media drawer requires additional props:
                        ...(THIRD_PARTY_MEDIA_EVENT_TYPES.includes(event.type) && {
                          drawerType: MENTIONS_AND_RATINGS
                        })
                      };

                      openDashboardDrawer(newEvent, drawerContext, socialPostDrawerContext);
                    }}
                  >
                    Details
                  </Button>
                </TableCell>
                {/* Metrics */}
                {values.map(({ key, color, value }) => (
                  <TableCell key={key} align={value !== '-' ? 'left' : 'center'}>
                    <span style={{ color }}>{value}</span>
                  </TableCell>
                ))}
                {/* Linktoken */}
                <TableCell>
                  <div className={classes.cellContent}>
                    {sourceType && <SocialLogo type={sourceType} width={20} height={20} />}
                    <span>{name}</span>
                  </div>
                </TableCell>
                {/* Event content */}
                <TableCell>
                  <div className={classes.cellContent}>
                    {!!campaignEvent?.assets?.length && (
                      <span className={classes.inlineAsset}>
                        <AssetThumbnail
                          assets={campaignEvent?.assets}
                          width={20}
                          height={20}
                          rounded
                        />
                      </span>
                    )}
                    <span>{text}</span>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {list.length > 4 && (
        <Grid container justifyContent="center">
          <Typography
            style={{
              fontWeight: '500',
              fontSize: '12px',
              lineHeight: '18px'
            }}
          >
            {`+${list.length - 4} more event${list.length - 4 > 1 ? 's' : ''} on this day`}
          </Typography>
        </Grid>
      )}
    </div>
  );

  Flyover.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    dates: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    offset: PropTypes.number.isRequired
  };

  const Squares = ({ list }) => {
    const maxValue = Math.max(...list.map(({ count }) => count));
    const [flyover, setFlyover] = useState({});

    return (
      <div className={classes.squares}>
        {list.map(({ count, day, type, events }) => {
          const round = Math.round((count / maxValue) * 100) || 0;
          const squareId = type + day;
          let backgroundColor = '#ECECEC';

          // checking count will handle percentages rounded to zero
          if (round || count) {
            const { color } = [...percentageRanges]
              .reverse()
              .find(range => round >= range.percentage);
            backgroundColor = color;
          }

          return (
            <div
              key={squareId}
              style={{ backgroundColor }}
              onMouseEnter={e => setFlyover({ id: squareId, offset: e.clientX })}
              onMouseLeave={() => setFlyover({})}
              onClick={() => squareClick(day)}
              onKeyPress={() => squareClick(day)}
              role="presentation"
            >
              {!!events.length && flyover.id === squareId && (
                <Flyover list={events} dates={[day]} offset={flyover.offset} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  Squares.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({ count: PropTypes.number.isRequired }).isRequired)
      .isRequired
  };

  const Days = ({ list }) => (
    <div className={classes.days}>
      {list.map(([timestamp, day]) => {
        const isMonthStart = day === '1';
        const isSelectedDate = moment.utc(timestamp).isSame(selectedDates.start, 'd');
        return (
          <span
            className={`${classes.day} ${isMonthStart ? classes.monthStart : ''}`}
            key={timestamp}
          >
            <span>{day}</span>
            {isSelectedDate && <div className={classes.selectedDate} />}
            {isMonthStart ? (
              <span style={{ paddingLeft: 10, color: '#0A1734' }}>
                {moment.utc(timestamp).format('MMMM')}
              </span>
            ) : (
              <br />
            )}
          </span>
        );
      })}
    </div>
  );

  Days.propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.string.isRequired, PropTypes.string.isRequired).isRequired
    ).isRequired
  };

  const AnnoFlyover = ({ list }) => {
    const { x, y, offset, annotationIds } = list;

    const formatAnnotationDates = annotation => {
      const { start_date, end_date } = annotation;

      const dateArray = [
        ...new Set([
          moment.utc(start_date).format('MM/DD/YYYY'),
          moment.utc(end_date).format('MM/DD/YYYY')
        ])
      ];

      return `${dateArray.join(' - ')}`;
    };

    return (
      <div
        style={{
          position: 'absolute',
          left: `${x}px`,
          top: `${y}px`
        }}
      >
        <div
          className={classes.flyover}
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '600px',
            width: 'max-content',
            overflowY: 'auto',
            paddingBottom: '15px',
            ...(offset + flyoverMaxWidth > window.innerWidth ? { right: '0px' } : { left: '0px' })
          }}
          role="button"
          aria-hidden="true"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <div className={classes.flyoverTitle}>Annotations</div>
          <Grid
            style={{
              display: 'flex'
            }}
          >
            <div className={classes.annotationIcon}>
              <img src={AnnotationBlueIcon} alt="Annotation icon" width={20} height={20} />
            </div>
            <Grid className={classes.annotationsList}>
              {annotationIds.map(id => (
                <Grid key={id} className={classes.annotationRow}>
                  <div className={classes.annotationText}>{annotationsLookup[id].message}</div>
                  <div className={classes.annotationDates}>
                    {formatAnnotationDates(annotationsLookup[id])}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  AnnoFlyover.propTypes = {
    list: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
      offset: PropTypes.number.isRequired,
      annotationIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    }).isRequired
  };

  // this element is only for display, the hover and lookups are done by day
  const Annotations = ({ list }) => {
    const gridTemplateColumns = `${list
      .map(({ length }) => `${(squareSize + squareHorizontalGap) * length - squareHorizontalGap}px`)
      .join(' ')}`;

    return (
      <div className={classes.annotations} style={{ pointerEvents: 'none', gridTemplateColumns }}>
        {list.map(({ count, id, length }) => {
          return count && length ? (
            <span key={id} className={classes.annotation} />
          ) : (
            <span key={id} />
          );
        })}
      </div>
    );
  };

  Annotations.propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.shape({
        length: PropTypes.number.isRequired
      }).isRequired
    ).isRequired
  };

  const AnnotationsByDay = ({ list }) => {
    const [flyover, setFlyover] = useState({});
    const annoRef = useRef([]);

    useEffect(() => {
      annoRef.current = annoRef.current.slice(0, list.length);
    }, [list]);

    return (
      <div className={classes.annotationDays} style={{ gridAutoFlow: 'column' }}>
        {list.map(([timestamp, annotationIds], i) => {
          return annotationIds.length > 0 ? (
            <span
              key={timestamp}
              className={classes.annotationDay}
              onMouseEnter={e => {
                const { left, top } = annoRef.current[i].getBoundingClientRect();
                const x = e.clientX - left;
                const y = e.clientY - top;

                setFlyover({ timestamp, x, y, offset: e.clientX, annotationIds });
              }}
              onMouseLeave={() => setFlyover({})}
              role="button"
              aria-hidden="true"
              // eslint-disable-next-line no-return-assign
              ref={e => (annoRef.current[i] = e)}
            >
              {annotationIds && flyover.timestamp === timestamp && <AnnoFlyover list={flyover} />}
            </span>
          ) : (
            <span key={timestamp} />
          );
        })}
      </div>
    );
  };

  AnnotationsByDay.propTypes = {
    list: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      )
    ).isRequired
  };

  useEffect(() => {
    if (eventsError) handleGraphQLError(eventsError);
    if (annotationsError) handleGraphQLError(annotationsError);
  }, [eventsError, annotationsError]);

  return (
    <Grid container justifyContent="center" className={classes.container}>
      {/* Header Row /w Tab and Switch */}
      <Grid container justifyContent="space-between" alignItems="center">
        <Tabs value={0} onChange={() => {}}>
          <Tab
            label="Detected Events"
            classes={{
              root: classes.tabOverRide
            }}
          />
        </Tabs>

        <div className={classes.switchContainer}>
          <Typography
            className={classes.switchHeaderText}
            // if the toggle switch on then we want New Events to be in-active(grey)
            style={{ color: isToggleSwitchOn ? colors.doveGray : colors.navy }}
          >
            New Events
          </Typography>
          <AlbSwitch
            onChange={event => setIsToggleSwitchOn(event.target.checked)}
            checked={isToggleSwitchOn}
            name="useCPA"
            inputProps={{ 'aria-label': 'use New Events or All Events' }}
          />
          <Typography
            className={classes.switchHeaderText}
            // if the toggle switch on then we want All Events to be active(navy)
            style={{ color: isToggleSwitchOn ? colors.navy : colors.doveGray }}
          >
            All Events
          </Typography>
        </div>
      </Grid>

      {/* Body Row /w Heat Map and Legend */}
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.bodyContainer}
        style={{ opacity: eventsLoading ? 0.5 : 1 }}
      >
        {eventsLoading && (
          <Grid container className={classes.loadingOverlay}>
            <AlbLoading />
          </Grid>
        )}

        {/* Heat Map */}
        <Box className={classes.graph}>
          {/* 
            THE .graph class defines a 2x3 grid:

            types and squares grid areas will render 4 rows

            types squares
            empty days
            empty annotations
          */}
          {!!squares.length && (
            <>
              <div className={classes.types}>
                <span>
                  Media <span style={{ fontSize: '10px' }}>(3rd Party)</span>
                </span>
                <span>Social</span>
                <span>Web & Apps</span>
                <span>Conversion</span>
              </div>

              <Squares list={squares} />
              <Days list={days} />
              {annotations.map(layer => (
                // key is either the id of the leading space or the annotation
                <Annotations key={layer[0].id} list={layer} />
              ))}
              <AnnotationsByDay list={annotationsByDayLookup} />
            </>
          )}
        </Box>

        {/* Legend */}
        {!!squares.length && (
          <Grid container justifyContent="center">
            <EventsLegend max={max} ranges={percentageRanges} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DetectedEventsHeatmap;

DetectedEventsHeatmap.propTypes = {
  isToggleSwitchOn: PropTypes.bool.isRequired,
  setIsToggleSwitchOn: PropTypes.func.isRequired,
  selectedDates: PropTypes.shape().isRequired,
  setSelectedDates: PropTypes.func.isRequired
};
