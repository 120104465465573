/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Grid, Paper, IconButton, Select, MenuItem, Checkbox, TextField } from '@material-ui/core';
import { ExpandMore, SearchOutlined } from '@material-ui/icons';
import Box from 'components/Box';
import AlbLoading from 'components/AlbLoading';
import CausalChainJustificationModal from 'components/AlembicModalConfirm/CausalChainJustificationModal';
import theme from 'theme';
import { getDrawerHeaderTitle, getReadableDetectedEventTitle } from 'util/detectedEvents';
import CausalAccordion from './CausalAccordion';
import Feedback from './Feedback';

const sortByTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    },
    MuiSelect: {
      select: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        paddingTop: '11px',
        paddingLeft: '16px',

        '&:focus': {
          backgroundColor: 'unset'
        }
      }
    }
  }
});

const terminatingTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiPopover: {
      root: {
        zIndex: '1400 !important'
      }
    },
    MuiSelect: {
      select: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        paddingTop: '11px',
        paddingLeft: '16px',

        '&:focus': {
          backgroundColor: 'unset'
        }
      }
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '0px'
      }
    },
    MuiMenuItem: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px'
      }
    }
  }
});

const searchTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInputBase: {
      root: {
        maxWidth: '213px'
      },
      input: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        '&::placeholder': {
          color: '#0D1A3A',
          opacity: 1
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: '10px'
      }
    }
  }
});

const useStyles = makeStyles({
  box: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '10px',
    padding: '36px',
    marginBottom: '48px'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontWeight: 500
  },
  causalTitle: {
    fontSize: '20px',
    color: '#0A1734'
  },
  dropdownLabel: {
    fontSize: '15px',
    color: '#0D1A3A'
  },
  altTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    margin: '12px 0px'
  },
  summaryTitle: {
    position: 'relative',
    width: '100%',
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    direction: 'ltr'
  },
  actions: {
    position: 'absolute',
    top: '-9px',
    right: '0px'
  },
  body: {
    fontSize: '20px',
    overflow: 'hidden'
  },
  actionButton: {
    color: '#000000'
  },
  disabled: {
    backgroundColor: 'transparent',
    cursor: 'default',
    pointerEvents: 'none'
  },
  footerButton: {
    color: '#FFFFFF',
    background: '#0A1934',
    padding: '8px 22px',
    '& > span': {
      fontSize: '14px'
    },
    '&:hover': {
      backgroundColor: '#3c5a99'
    }
  },
  email: {
    color: '#000000',
    fontSize: '0px'
  },
  insightRow: {
    justifyContent: 'center'
  },
  summary: {
    display: 'flex'
  },
  separator: {
    height: '1px',
    width: '100%',
    backgroundColor: '#979797',
    margin: '28px 0px'
  },
  accordionContainer: {
    opacity: 1,
    width: '100%',
    margin: '30px 0 0 0'
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h1': {
      fontSize: '28px',
      fontWeight: 500
    },
    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  },
  select: {
    width: '190px',
    height: '41px',
    color: '#0D1A3A',
    marginLeft: '10px'
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

/**
 * @method
 * @summary This component renders the results and justify sections for the GraphFish UI
 * @name InsightsSummary
 * @return {Object} - React JSX
 */

const InsightsSummary = props => {
  const {
    justifyData,
    useSearchCausalChains,
    terminatingEvents,
    selectedEventTypes,
    setSelectedEventTypes,
    loading,
    refetch,
    sort,
    setSort,
    searchInputValue,
    setSearchInputValue
  } = props;
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [visibleJustification, setVisibleJustification] = useState(false);

  const [isJustificationModalOpen, toggleJustificationModal] = useState(false);
  const [selectedCausalChain, setSelectedCausalChain] = useState(null);

  useEffect(() => {
    // handle re(fetching) the selected causal chain when feedback is provided in the modal
    if (isJustificationModalOpen) {
      const tempSelectedCausalChain = justifyData.find(item => item.id === selectedCausalChain.id);

      setSelectedCausalChain(tempSelectedCausalChain);
    }
    setVisibleJustification(true);
  }, [justifyData]);

  useEffect(() => {
    if (searchInputValue.length > 0) {
      useSearchCausalChains({
        variables: {
          query: searchInputValue.trim(),
          sortField: sort,
          terminatingEvents
        },
        fetchPolicy: 'no-cache'
      });
    }
  }, [sort]);

  const expandJustify = (e, causalChain) => {
    setSelectedCausalChain(causalChain);
    toggleJustificationModal(true);
    e.stopPropagation();
  };

  const options = [
    { field: 'startDate', label: 'Start Date' },
    { field: 'endDate', label: 'End Date' },
    { field: 'revenue', label: 'Revenue' }
  ];

  const renderSelected = selected =>
    selected.length === terminatingEvents.length
      ? 'All'
      : selected
          .map(eventType =>
            getDrawerHeaderTitle({
              event: { type: getReadableDetectedEventTitle(eventType) }
            })
          )
          .join(', ');

  const searchCausalChains = e => {
    e.preventDefault();
    useSearchCausalChains({
      variables: {
        query: searchInputValue.trim(),
        sortField: sort,
        terminatingEvents
      },
      fetchPolicy: 'no-cache'
    });
  };

  const handleSearchInputChange = e => {
    setSearchInputValue(e.target.value);
  };

  return (
    <Grid container direction="column">
      <Paper className={`${classes.box}`}>
        {loading && (
          <Box className={classes.loadingOverlay}>
            <AlbLoading />
          </Box>
        )}
        <Grid container className={classes.headerContainer} spacing={2}>
          <Grid item className={classes.causalTitle}>
            Causal Chains
          </Grid>
          {terminatingEvents?.length > 0 && (
            <Grid item className={classes.dropdownLabel}>
              <MuiThemeProvider theme={terminatingTheme}>
                Filter by Terminating Event:
                <Select
                  className={classes.select}
                  variant="outlined"
                  multiple
                  labelId="input-label"
                  value={selectedEventTypes}
                  renderValue={renderSelected}
                  onChange={e => setSelectedEventTypes(e.target.value)}
                  IconComponent={ExpandMore}
                >
                  {terminatingEvents.map(value => (
                    <MenuItem key={value} value={value} onClick={() => {}}>
                      <Checkbox checked={selectedEventTypes.includes(value)} />
                      {/* convert label and use drawer header as fallback */}
                      {getDrawerHeaderTitle({
                        event: { type: getReadableDetectedEventTitle(value) }
                      })}
                    </MenuItem>
                  ))}
                </Select>
              </MuiThemeProvider>
            </Grid>
          )}
          <Grid item className={classes.dropdownLabel}>
            <MuiThemeProvider theme={sortByTheme}>
              <span>
                Sort By:
                <Select
                  className={classes.select}
                  variant="outlined"
                  labelId="input-label"
                  value={sort}
                  onChange={e => setSort(e.target.value)}
                  IconComponent={ExpandMore}
                >
                  {options.map(({ field, label }) => (
                    <MenuItem key={field} value={field} onClick={() => {}}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </span>
            </MuiThemeProvider>
          </Grid>
          <Grid item>
            <MuiThemeProvider theme={searchTheme}>
              <form onSubmit={searchCausalChains}>
                <TextField
                  fullWidth
                  id="standard-bare"
                  variant="outlined"
                  placeholder="Search"
                  value={searchInputValue}
                  onChange={handleSearchInputChange}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={searchCausalChains}>
                        <SearchOutlined />
                      </IconButton>
                    )
                  }}
                />
              </form>
            </MuiThemeProvider>
          </Grid>
        </Grid>

        {!loading && justifyData?.length === 0 && visibleJustification && (
          <div className={classes.altTitle}>
            There are no causal chains visible for the selected event types.
          </div>
        )}
        {justifyData?.length > 0 && visibleJustification && (
          <>
            <div className={`${classes.accordionContainer}`}>
              <CausalAccordion justifyData={justifyData} expandJustify={expandJustify} />
            </div>

            {selectedCausalChain?.id != null && isJustificationModalOpen && (
              <CausalChainJustificationModal
                title={selectedCausalChain.title}
                justification={selectedCausalChain.justification}
                endDate={selectedCausalChain.end_date}
                isModalOpen={isJustificationModalOpen}
                onChange={e => toggleJustificationModal(e.isModalOpen)}
                feedbackComponent={
                  <Feedback
                    data={{ id: selectedCausalChain.id, feedback: selectedCausalChain.feedback }}
                    refetch={refetch}
                  />
                }
              />
            )}
          </>
        )}
      </Paper>
    </Grid>
  );
};

InsightsSummary.propTypes = {
  justifyData: PropTypes.arrayOf(PropTypes.shape()),
  terminatingEvents: PropTypes.arrayOf(PropTypes.string),
  selectedEventTypes: PropTypes.arrayOf(PropTypes.string),
  setSelectedEventTypes: PropTypes.func,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  useSearchCausalChains: PropTypes.func,
  searchInputValue: PropTypes.string,
  setSearchInputValue: PropTypes.func
};

InsightsSummary.defaultProps = {
  justifyData: null,
  terminatingEvents: [],
  selectedEventTypes: [],
  setSelectedEventTypes: () => {},
  loading: false,
  refetch: () => {},
  sort: null,
  setSort: () => {},
  useSearchCausalChains: () => {},
  searchInputValue: '',
  setSearchInputValue: () => {}
};

export default InsightsSummary;
