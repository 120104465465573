import * as ActionTypes from 'actions/types';

/*
  When the App is first open redux is initialized with these values.
*/
const initialState = {
  configStoreEventTypes: [],
  configStoreEventTitles: [],
  configStoreRevenueEvents: []
};

/**
 * @param {*} state current redux state
 * @param {*} action payload from dispatched action
 * @returns {Object} new state
 */
const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_EVENT_CONFIG:
      return Object.assign({}, state, {
        configStoreEventTypes: action.configStoreEventTypes,
        configStoreEventTitles: action.configStoreEventTitles,
        configStoreRevenueEvents: action.configStoreRevenueEvents
      });
    default:
      return state;
  }
};

export default configReducer;
