import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AlbDoughnutGraph from 'components/AlbGraphs/AlbDoughnutGraph';
import AlbBarGraph from 'components/AlbGraphs/AlbBarGraph';
import AlbDoughnutGraphLegend from 'components/AlbGraphs/AlbDoughnutGraphLegend';
import { topPostsColors } from 'util/colors';
import getValueAbbreviation from 'util/getValueAbbreviation';
import { DETECTED_EVENT_TYPE, getDetectorType, getRevenueEventTypes } from 'util/detectedEvents';
import { simpleStackedTooltip } from 'components/AlbGraphs/CustomTooltipTemplates';
import truncateEventLabel from 'util/truncateEventLabel';

const TRUNCATE_LENGTH = 47;

const useStyles = makeStyles({
  tooltip: {
    position: 'absolute',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    color: 'white',
    WebkitTransition: 'all .1s ease',
    transition: 'all .1s ease',
    pointerEvents: 'none',
    zIndex: 1,
    opacity: 0
  }
});

/**
 * @method
 * @summary This component renders a graph for an single event type in the @see CorrelationDrawerView
 * @name CorrelationDrawerGraph
 * @param {Object} props - React props passed to this component
 * @param {String} props.title - String representing the event_type, to be converted into a label
 * @param {Object[]} props.data - An array of values representing the magnitude per detectedEvent
 * @param {Object[]} props.labels - An array of { id - for mapping keys, and eventInfo for display } - 1:1 with the data array
 * @param {Boolean} [props.bar=false] - If true, data contains a negative value and will display a bar graph
 * @param {Number} props.index - The index of the graph, used to determine tooltip direction
 * @return {Object} - React JSX
 */
const CorrelationDrawerGraph = props => {
  const { title, data, labels, bar, index } = props;
  const classes = useStyles();

  const canvasRef = useRef();
  const tooltipRef = useRef();

  const direction = (index + 1) % 3 === 0 ? 'rtl' : 'ltr';

  const total = data.reduce((acc, curr) => {
    // eslint-disable-next-line no-param-reassign
    acc += Math.abs(curr);
    return acc;
  }, 0);

  const custom = tooltip => {
    const { dataPoints, caretX, caretY, title: tooltipTitle, labelColors, opacity } = tooltip;
    // provide offset of canvas element to nearest relative parent
    const { offsetTop, offsetLeft } = canvasRef.current.canvas;
    const { current } = tooltipRef;

    if (opacity && dataPoints.length) {
      let legendTooltipLabelData = [];

      if (bar) {
        legendTooltipLabelData = dataPoints.map(({ yLabel }, i) => ({
          value: yLabel.toLocaleString(),
          color: labelColors[i].backgroundColor
        }));
      } else {
        const [value] = tooltip.body[0].lines;
        const { backgroundColor } = labelColors[0];

        legendTooltipLabelData = [{ value, color: backgroundColor }];
      }

      Object.assign(current, {
        innerHTML: simpleStackedTooltip(tooltipTitle, legendTooltipLabelData)
      });
    }

    Object.assign(current.style, {
      opacity,
      zIndex: 2,
      // hide the tooltip element if no data - even the empty div
      display: dataPoints?.length ? 'unset' : 'none',
      top: `${caretY + offsetTop - 10}px`,
      left: `${caretX + offsetLeft + 10}px`
    });
  };

  const graph = canvas => {
    canvasRef.current = canvas.getContext('2d');

    const graphDatasets = bar
      ? data.map((value, i) => ({
          data: [value],
          label: labels[i].id,
          backgroundColor: topPostsColors[i],
          borderWidth: 0
        }))
      : [
          {
            data,
            backgroundColor: topPostsColors,
            borderWidth: 0
          }
        ];

    const graphLabels = bar ? [''] : labels.map(({ postBody }) => postBody);

    return { labels: graphLabels, datasets: graphDatasets };
  };

  const PIPE_EVENTS = [
    DETECTED_EVENT_TYPE.IM_POST_RESURGE,
    DETECTED_EVENT_TYPE.IM_LINKTOKEN_RESURGE,
    DETECTED_EVENT_TYPE.IM_SINGLE_POST_INITIAL_SURGE,
    DETECTED_EVENT_TYPE.IM_DAILY_POST_INITIAL_SURGE,
    DETECTED_EVENT_TYPE.EG_POST_RESURGE,
    DETECTED_EVENT_TYPE.EG_LINKTOKEN_RESURGE,
    DETECTED_EVENT_TYPE.EG_SINGLE_POST_INITIAL_SURGE,
    DETECTED_EVENT_TYPE.EG_DAILY_POST_INITIAL_SURGE,
    // DETECTED_EVENT_TYPE.FL_POST_RESURGE,
    // DETECTED_EVENT_TYPE.FL_LINKTOKEN_RESURGE,
    // DETECTED_EVENT_TYPE.FL_SINGLE_POST_INITIAL_SURGE,
    // DETECTED_EVENT_TYPE.FL_DAILY_POST_INITIAL_SURGE,
    DETECTED_EVENT_TYPE.SA_ARTICLE_COUNT,
    DETECTED_EVENT_TYPE.SA_TOTAL_SHARES
  ];

  return (
    <div style={{ display: 'grid', rowGap: '30px' }}>
      <span
        style={{
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontWeight: 500,
          textTransform: 'uppercase'
        }}
      >
        {`${getDetectorType()[title]} Δ`}
      </span>
      <div style={{ position: 'relative' }}>
        {bar ? (
          <AlbBarGraph
            data={graph}
            simple
            customTooltip={custom}
            currency={getRevenueEventTypes().includes(title)}
          />
        ) : (
          <AlbDoughnutGraph
            data={graph}
            count={`${Math.abs(Math.round((data[0] / total) * 100))}%`}
            label={truncateEventLabel(labels[0].postBody, TRUNCATE_LENGTH)}
            large
            customTooltip={custom}
            currency={getRevenueEventTypes().includes(title)}
          />
        )}
        <div className={classes.tooltip} style={{ direction }} ref={tooltipRef} />
      </div>
      <Grid container item direction="column" justifyContent="center">
        {data.map((value, i) => (
          <AlbDoughnutGraphLegend
            key={labels[i].id}
            boxColor={topPostsColors[i]}
            position={`${truncateEventLabel(
              `${labels[i].postBody}${labels[i].addOn}`,
              TRUNCATE_LENGTH
            )}`}
            value={getValueAbbreviation(value, getRevenueEventTypes().includes(title))}
            subValue={`${Math.abs(Math.round((value / total) * 100))}%`}
            tooltip={
              PIPE_EVENTS.includes(title)
                ? `${labels[i].postBody}${labels[i].addOn} | ${labels[i].sourceName}`
                : `${labels[i].postBody}${labels[i].addOn}`
            }
            large
          />
        ))}
        <AlbDoughnutGraphLegend
          boxColor="#000000"
          position="Total"
          value={getValueAbbreviation(total, getRevenueEventTypes().includes(title))}
          subValue="100%"
          large
        />
      </Grid>
    </div>
  );
};

CorrelationDrawerGraph.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      sourceName: PropTypes.string,
      postBody: PropTypes.string,
      addOn: PropTypes.string
    })
  ).isRequired,
  bar: PropTypes.bool,
  index: PropTypes.number.isRequired
};

CorrelationDrawerGraph.defaultProps = {
  bar: false
};

export default CorrelationDrawerGraph;
