import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';

export const MDXContentContext = createContext();

export const MDXContentContextProvider = props => {
  const { children } = props;
  const [activeEventId, setActiveEventId] = useState(null);

  return (
    <MDXContentContext.Provider value={{ activeEventId, setActiveEventId }}>
      {children}
    </MDXContentContext.Provider>
  );
};

MDXContentContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};
