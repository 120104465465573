import { apolloClientQuery } from 'middleware/api';
import { EVENT_CONFIG } from 'gql/config_override';
import { FETCH_EVENT_CONFIG } from './types';

export const fetchEventConfigSuccess = data => ({ type: FETCH_EVENT_CONFIG, ...data });

/**
 * Redux Action - queries container event config
 */
export const fetchEventConfig = () => {
  return async dispatch => {
    const { data } = await apolloClientQuery(EVENT_CONFIG, {});

    dispatch(fetchEventConfigSuccess(data));
  };
};
