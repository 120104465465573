import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { evaluate } from '@mdx-js/mdx';
import * as runtime from 'react/jsx-runtime';
import { MDXContentContextProvider } from 'contexts/MDXContentContext';

const MDXComponents = ['EventLink'];

// function replaceEventLinksWithDescription(markdownContent) {
//   // Regex pattern to find <EventLink ... /> and capture the content within description=""
//   const eventLinkPattern = /<EventLink[^>]*description="([^"]*)"[^>]*\/?>/g;

//   // Function to replace the entire EventLink with just the description content
//   function replaceWithDescription(match, description) {
//     return description;
//   }

//   // Replace all instances of EventLink in the markdown content with the description content
//   const fixedContent = markdownContent.replace(eventLinkPattern, replaceWithDescription);

//   return fixedContent;
// }

const escapeSpecialCharacters = markdown => {
  // TODO: temp hack until upstream is fixed
  // const markdownNew = replaceEventLinksWithDescription(markdown);

  // escape angle brackets containing links
  // MDX only attempts to parse as a component if '<' is not escaped
  let escapedMarkdown = markdown.replace(/</g, '\\<').replace(/{/g, '\\{');

  // unescape MDX components
  MDXComponents.forEach(component => {
    escapedMarkdown = escapedMarkdown.split(`\\<${component}`).join(`<${component}`);
  });

  return escapedMarkdown;
};

const evaluateMDX = (content, element) => {
  const [component, setComponent] = useState({ default: element });
  const escapedMarkdown = escapeSpecialCharacters(content);

  useEffect(() => {
    evaluate(escapedMarkdown, runtime).then(result => setComponent(result));
  }, [content]);

  return component;
};

const MDXContent = props => {
  const { content, components, element } = props;
  const Content = evaluateMDX(content, element).default;

  return (
    <MDXContentContextProvider>
      <Content components={components} element={element} />
    </MDXContentContextProvider>
  );
};

MDXContent.propTypes = {
  content: PropTypes.string.isRequired,
  components: PropTypes.shape().isRequired,
  element: PropTypes.string
};

MDXContent.defaultProps = {
  element: 'div'
};

export default MDXContent;
